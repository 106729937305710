import React, { useState } from "react";
import TeamCard from "../components/TeamCard";
import TeamData from "../data/team.json";
import "../css/Team.css";

export default function TeamPage() {
  document.title = "Team | Optima 2023";

  const [show, setShow] = useState("Core Team Heads");

  return (
    <div className="Team-body">
      <h1 className="Team-heading">OUR TEAM</h1>
      <div className="Team-row">
        {Object.keys(TeamData).map((val, idx) => {
          return (
            <button
              key={idx}
              onClick={() => {
                setShow(val);
              }}
              className={"Team-btn" + (show === val ? " active" : "")}
            >
              {val}
            </button>
          );
        })}
      </div>
      <div className="Team-row">
        {TeamData[show].map((val, idx) => (
          <TeamCard
            key={idx}
            img={val.Image}
            name={val.Name}
            portfolio={val.Portfolio}
            email={val.Email}
            fb={val.fb}
            linkedin={val.li}
          />
        ))}
      </div>
    </div>
  );
}
