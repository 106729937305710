import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Redirect from "./components/Redirect";
import ComingSoon from "./pages/ComingSoon.js";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home";
import About from "./pages/About";
import Competitions from "./pages/Competitions";
import GuestLectures from "./pages/GuestLectures";
import Workshops from "./pages/Workshops";
import Sponsors from "./pages/Sponsors";
import Schedule from "./pages/Schedule";
import Team from "./pages/Team";
import Register from "./pages/Register";
import Registration from "./pages/Registration";
import CompetitionsData from "./data/competitions.json";
import "./App.css";

function App() {
	return (
		<Router>
			<Navbar />
			<Routes>
				<Route
					path="/selections"
					element={
						<Redirect href="https://forms.gle/cuqwFdVSATvJCNDbA" />
					}
				/>
				<Route exact path="/" element={<Home />} />
				<Route path="/about" element={<About />} />
				<Route path="/competitions" element={<Competitions />} />
				{Object.keys(CompetitionsData).map((eventID) => {
					return (
						<Route
							key={eventID}
							path={CompetitionsData[eventID].path}
							element={
								<Redirect
									href={CompetitionsData[eventID].regLink}
								/>
							}
						/>
					);
				})}
				<Route
					path="/Operheimer/PS"
					element={
						<Redirect href="https://drive.google.com/drive/folders/1K4H1yrvhj-Jh8WlLdsKdyLAAE5w9M8R_" />
					}
				/>
				<Route
					path="/Operheimer/Whatsapp"
					element={
						<Redirect href="https://chat.whatsapp.com/BSWUHq9rx5a5wREn0mDKqZ" />
					}
				/>
				<Route
					path="/Oper/submit"
					element={
						<Redirect href="https://forms.gle/iUR3uvNTQee1uHN39" />
					}
				/>
				<Route
					path="/Fin-A-lytics/PS"
					element={
						<Redirect href="https://drive.google.com/drive/folders/1gMGL-C_enPeGM1nrJOcyFMlZ4eWlEK9f?usp=drive_link" />
					}
				/>
				<Route
					path="/Fin-A-lytics/Whatsapp"
					element={
						<Redirect href="https://chat.whatsapp.com/GHcEcbyFRyZ1x0j1SA6cEl" />
					}
				/>
				<Route
					path="/NLP.py/PS"
					element={
						<Redirect href="https://drive.google.com/drive/folders/1XE_wABK9Fy2SY5LhjGHwGoWnWa-p_1hQ?usp=sharing" />
					}
				/>
				<Route
					path="/NLP.py/Whatsapp"
					element={
						<Redirect href="https://chat.whatsapp.com/LC1nV6FI6MIEdojw8ZtqS6" />
					}
				/>
				<Route
					path="/Networsify/PS"
					element={
						<Redirect href="https://drive.google.com/drive/folders/1wawhXDFtGOO5hIDiYY5ceoPigpflbzg_?usp=sharing" />
					}
				/>
				<Route
					path="/Networsify/Whatsapp"
					element={
						<Redirect href="https://chat.whatsapp.com/EYywCEuUzg7Gycugt0XsEN" />
					}
				/>
				<Route
					path="/360Degrees/PS"
					element={
						<Redirect href="https://drive.google.com/drive/folders/1xktyMdJrQiZA2OcqGmnOrMza3tuOA6ih?usp=sharing" />
					}
				/>
				<Route
					path="/360Degrees/Whatsapp"
					element={
						<Redirect href="https://chat.whatsapp.com/LTtRBmyy75s87euKJ9zROC" />
					}
				/>
				<Route
					path="/Innovate-X/PS"
					element={
						<Redirect href="https://drive.google.com/drive/folders/1wAf-OgbBA2XLvdeQ5gasyycDEuU1t3oi?usp=sharing" />
					}
				/>
				<Route
					path="/Innovate-X/Whatsapp"
					element={
						<Redirect href="https://chat.whatsapp.com/I6ECEpVqwNqDSAG7AeOGjp" />
					}
				/>
				<Route path="/guest-lectures" element={<GuestLectures />} />
				<Route path="/workshops" element={<Workshops />} />
				<Route path="/sponsors" element={<Sponsors />} />
				<Route path="/schedule" element={<Schedule />} />
				<Route path="/team" element={<Team />} />
				<Route path="/register" element={<Register />} />
				<Route path="/registration" element={<Registration />} />
				<Route
					path="/Whatsapp"
					element={
						<Redirect href="https://chat.whatsapp.com/IEdp2cLmXnSLALXuOzHCNc" />
					}
				/>
				<Route
					path="*"
					element={
						<h1
							style={{
								textAlign: "center",
								marginBlock: "50px",
							}}
						>
							404 Not Found
						</h1>
					}
				/>
			</Routes>
			<Footer />
			<ScrollToTop />
		</Router>
	);
}

export default App;
