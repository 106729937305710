import { useEffect, useState } from "react";
import "../css/Registration.css";

const RegistrationForm = () => {
  document.title = "Registration | Optima 2023";

  const [regData, setRegData] = useState({
    FirstName: "",
    LastName: "",
    DateOfBirth: "",
    Gender: "",
    Phone: "",
    Email: "",
    College: "",
    City: "",
    State: "",
    LevelOfStudy: "",
  });

  const [OPDetails, setOPDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("OPDetails")) {
      setOPDetails(JSON.parse(localStorage.getItem("OPDetails")));
    }
  }, [localStorage.getItem("OPDetails")]);

  const stateOptions = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Jammu and Kashmir",
    "Ladakh",
    "Lakshadweep",
    "Puducherry",
  ].sort();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegData({
      ...regData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(
      "https://script.google.com/macros/s/AKfycbyIhs7u9UOqOS_Jgmd8_7ilOgncB80X_497WzpCjfVjrP5_bNu0pE5Q4cLnSsMe7oLffQ/exec",
      {
        method: "POST",
        body: new FormData(document.forms["submit-to-google-sheet"]),
      }
    )
      .then((res) => {
        res.json().then((res) => {
          if (res.result === "success") {
            setOPDetails(res.details);

            let keys = [
              "FirstName",
              "LastName",
              "DateOfBirth",
              "Gender",
              "Phone",
              "Email",
              "College",
              "City",
              "State",
              "LevelOfStudy",
              "OPID",
              "DateTime",
            ];
            let temp = {};
            keys.forEach((key, idx) => {
              temp = {
                ...temp,
                [key]: res.details[idx],
              };
            });
            localStorage.setItem("OPDetails", JSON.stringify(temp));
            alert("Registered Successfully! Your OPID is " + temp.OPID + ".");
            setLoading(false);
            window.scrollTo(0, 0);
          } else {
            alert("Error! Try again later.");
            setLoading(false);
          }
        });
      })
      .catch((err) => {
        alert("Error!", err.message);
        setLoading(false);
      });
  };

  const regDataBox = OPDetails ? (
    <center>
      Your OPID is{" "}
      <b>
        <u>{OPDetails.OPID}</u>
      </b>{" "}
      <a
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          navigator.clipboard.writeText(OPDetails.OPID);
          alert("Copied to clipboard!");
        }}
      >
        (Copy)
      </a>
      <br />
      <br />
      <h2>Registration Details</h2>
      <div className="registration-box">
        <div>
          First Name
          <br />
          {OPDetails.FirstName}
        </div>
        <div>
          Last Name
          <br />
          {OPDetails.LastName}
        </div>
        <div>
          Date of Birth
          <br />
          {OPDetails.DateOfBirth}
        </div>
        <div>
          Gender
          <br />
          {OPDetails.Gender}
        </div>
        <div>
          Phone
          <br />
          {OPDetails.Phone}
        </div>
        <div>
          Email
          <br />
          {OPDetails.Email}
        </div>
        <div>
          College / School
          <br />
          {OPDetails.College}
        </div>
        <div>
          City
          <br />
          {OPDetails.City}
        </div>
        <div>
          State
          <br />
          {OPDetails.State}
        </div>
        <div>
          Level of Study
          <br />
          {OPDetails.LevelOfStudy}
        </div>
        <div>
          Registration Time
          <br />
          {OPDetails.DateTime}
        </div>
      </div>
      <div
        style={{
          marginBlock: "20px",
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          textAlign: "left",
        }}
      >
        Your registration will be confirmed only after you pay {" ("}exclusively
        applicable to non-IIT Kharagpur students{")"}. Attend the Fest on-campus
        by paying a one-time fee of <b style={{ color: "orange" }}>₹1000</b>. If
        you pay <b style={{ color: "orange" }}>₹600</b>, accommodation will not
        be provided.
        <br />
        <br />
        <center>
          {/* <h5>Pay now if not paid already.</h5> */}
          {/* <button
            className="btn"
            style={{
              backgroundColor: "green",
              color: "white",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.preventDefault();
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSdF0aMVQ3wXXFQah-VUP_XCEVRg80HAmsbasrjqoZe8uP4pJw/viewform?usp=pp_url&entry.1334646602=" +
                  OPDetails.FirstName +
                  "+" +
                  OPDetails.LastName +
                  "&entry.1614741187=" +
                  OPDetails.OPID,
                "_blank"
              );
            }}
          >
            Pay Now
          </button> */}
        </center>
      </div>
      <button
        className="btn"
        style={{
          backgroundColor: "#f44336",
          color: "white",
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
        onClick={() => {
          setOPDetails(null);
          localStorage.removeItem("OPDetails");
        }}
      >
        Register Again
      </button>
    </center>
  ) : null;

  const regForm = (
    <>
      <div className="registration-section">
        <div className="registration-row">
          <h2 className="registration-col">Personal Details</h2>
        </div>
        <div className="registration-row">
          <label className="registration-col">
            First Name:
            <input
              type="text"
              name="FirstName"
              value={regData.FirstName}
              required
              onChange={handleChange}
              placeholder="First Name"
            />
          </label>
          <label className="registration-col">
            Last Name:
            <input
              type="text"
              name="LastName"
              value={regData.LastName}
              required
              onChange={handleChange}
              placeholder="Last Name"
            />
          </label>
        </div>
        <div className="registration-row">
          <label className="registration-col">
            Date of Birth:
            <input
              type="date"
              name="DateOfBirth"
              value={regData.DateOfBirth}
              required
              onChange={handleChange}
            />
          </label>
          <label className="registration-col">
            Gender:
            <select
              name="Gender"
              value={regData.Gender}
              required
              onChange={handleChange}
            >
              <option value="">Please choose an option</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </label>
        </div>
      </div>
      <div className="registration-section">
        <div className="registration-row">
          <h2 className="registration-col">Contact Details</h2>
        </div>
        <div className="registration-row">
          <label className="registration-col">
            Phone:
            <input
              type="tel"
              name="Phone"
              value={regData.Phone}
              required
              onChange={handleChange}
              placeholder="Phone Number"
            />
          </label>
          <label className="registration-col">
            Email:
            <input
              type="Email"
              name="Email"
              value={regData.Email}
              required
              onChange={handleChange}
              placeholder="Email Address"
            />
          </label>
        </div>
      </div>
      <div className="registration-section">
        <div className="registration-row">
          <h2 className="registration-col">Educational Details</h2>
        </div>
        <div className="registration-row">
          <label className="registration-col">
            College / School Name:
            <input
              type="text"
              name="College"
              value={regData.College}
              required
              onChange={handleChange}
              placeholder="College / School Name"
            />
          </label>
        </div>
        <div className="registration-row">
          <label className="registration-col">
            City:
            <input
              type="text"
              name="City"
              value={regData.City}
              required
              onChange={handleChange}
              placeholder="City"
            />
          </label>
          <label className="registration-col">
            State:
            <select
              name="State"
              value={regData.State}
              required
              onChange={handleChange}
            >
              <option value="">Please choose an option</option>
              {stateOptions.map((state, idx) => (
                <option key={idx} value={state}>
                  {state}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="registration-row">
          <label className="registration-col">
            Level of Study:
            <select
              name="LevelOfStudy"
              value={regData.LevelOfStudy}
              required
              onChange={handleChange}
            >
              <option value="">Please choose an option</option>
              <option value="High School">High School</option>
              <option value="Undergraduate">Undergraduate</option>
              <option value="Postgraduate">Postgraduate</option>
              <option value="Research">Research</option>
            </select>
          </label>
        </div>
      </div>
      <input
        type="submit"
        value={loading ? "Loading..." : "Register"}
        className="btn"
        disabled={loading}
      />
    </>
  );

  return (
    <section className="registration-page">
      <form
        name="submit-to-google-sheet"
        onSubmit={handleSubmit}
        className="registration-form"
      >
        <h1>OPTIMA 2023</h1>
        <center>Registration has closed. Kindly apply next year.</center>
        {/* {OPDetails ? regDataBox : regForm} */}
      </form>
    </section>
  );
};

export default RegistrationForm;
