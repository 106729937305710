import React from "react";
import { Link } from "react-router-dom";
import "../css/Competitons.css";
import CompetitionsData from "../data/competitions.json";

const CompeteCard = ({ data }) => {
  const { name, organiser, path, shortDesc, regLink, image, ps } = data;
  return (
    <div className="competitions-card">
      <img src={image} className="compete-card-img-top" alt={name} />
      <div className="compete-card-body d-flex flex-column justify-content-between">
        <h4 className="compete-card-title">{name}</h4>
        <h4 className="compete-card-spons">by {organiser}</h4>
        <p className="compete-card-text">{shortDesc}</p>
        <div className="d-flex justify-content-around">
          {regLink ? (
            <>
              <a href={regLink} target="_blank" className="btn btn-sm btn-dark">
                View Details / Register
              </a>
              {ps && (
                <a href={ps} target="_blank" className="btn btn-sm btn-dark">
                  Problem Statement
                </a>
              )}
            </>
          ) : (
            <a className="btn btn-sm btn-dark">Coming Soon</a>
          )}
        </div>
      </div>
    </div>
  );
};

export default function Competitons() {
  document.title = "Competitions | Optima 2023";
  return (
    <div className="competitions">
      <h1>Competitions</h1>
      <div className="competition_row">
        {Object.keys(CompetitionsData).map((eventID) => (
          <CompeteCard key={Number(eventID)} data={CompetitionsData[eventID]} />
        ))}
      </div>
    </div>
  );
}
