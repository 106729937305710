export default function Day2() {
  //   return <h1 style={{ marginBlock: "2rem" }}>Coming Soon</h1>;
  return (
    <div className="tl tl-day2">
      <div className="tl-container left-container">
        <img src="tl/ORMAE.jpeg" alt="" />
        <div className="tl-text-box">
          <h2>Operheimer</h2>
          <small>8:30 AM</small>
          <a href="https://maps.app.goo.gl/WiBZ3Af6SkEHMK2p9" target="_blank">
            Seminar Room, Department of Industrial and Systems Engineering
          </a>
          <span className="left-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container right-container">
        <img src="tl/Delhivery.jpg" alt="" />
        <div className="tl-text-box">
          <h2>NetworSify Finals</h2>
          <small>8:30 AM</small>
          <a href="https://maps.app.goo.gl/WiBZ3Af6SkEHMK2p9" target="_blank">
            C1, Department of Industrial and Systems Engineering
          </a>
          <span className="right-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container left-container">
        <img src="tl/DCVisionVR.png" alt="" />
        <div className="tl-text-box">
          <h2>Virtual Reality Workshop</h2>
          <small>10 AM</small>
          <a href="https://maps.app.goo.gl/4E9RiAob3fenRGjj9" target="_blank">
            NR-312, Nalanda Classroom Complex
          </a>
          <span className="left-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container right-container">
        <img src="tl/InnovateX.png" alt="" />
        <div className="tl-text-box">
          <h2>Innovate-X Finals</h2>
          <small>10:30 AM</small>
          <a href="https://maps.app.goo.gl/WiBZ3Af6SkEHMK2p9" target="_blank">
            C2, Department of Industrial and Systems Engineering
          </a>
          <span className="right-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container left-container">
        <img src="tl/Sabre.jpg" alt="" />
        <div className="tl-text-box">
          <h2>Fin-A-Lytics</h2>
          <small>12 PM</small>
          <a href="https://maps.app.goo.gl/WiBZ3Af6SkEHMK2p9" target="_blank">
            Seminar Room, Department of Industrial and Systems Engineering
          </a>
          <span className="left-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container right-container">
        <img src="spons/2023/Vicon.png" alt="" />
        <div className="tl-text-box">
          <h2>Optical 3D Motion Camera System Workshop</h2>
          <small>12 PM</small>
          <a href="https://maps.app.goo.gl/4E9RiAob3fenRGjj9" target="_blank">
            NR-312, Nalanda Classroom Complex
          </a>
          <span className="right-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container left-container">
        <img src="tl/FlexSim.jpg" alt="" />
        <div className="tl-text-box">
          <h2>OptiSim</h2>
          <small>1 PM</small>
          <a href="https://maps.app.goo.gl/4E9RiAob3fenRGjj9" target="_blank">
            NR-311, Nalanda Classroom Complex
          </a>
          <span className="left-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container right-container">
        <img src="tl/GFG.jpg" alt="" />
        <div className="tl-text-box">
          <h2>CodeLand Finals</h2>
          <small>1 PM</small>
          <a href="https://maps.app.goo.gl/4E9RiAob3fenRGjj9" target="_blank">
            NR-312, Nalanda Classroom Complex
          </a>
          <span className="right-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container left-container">
        <img src="tl/Bala_Srinivasan.jpeg" alt="" />
        <div className="tl-text-box">
          <h2>
            Bala Srinivasan {"("}Guest Lecture{")"}
          </h2>
          <small>4 PM</small>
          <a href="https://maps.app.goo.gl/5fRduL1ocaE75UKPA" target="_blank">
            Kalidas Auditorium
          </a>
          <span className="left-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container right-container">
        <img src="tl/Goda_Ramkumar.jpeg" alt="" />
        <div className="tl-text-box">
          <h2>
            {" "}
            Goda Ramkumar {"("}Guest Lecture{")"}
          </h2>
          <small>5:30 PM</small>
          <a href="https://maps.app.goo.gl/5fRduL1ocaE75UKPA" target="_blank">
            Kalidas Auditorium
          </a>
          <span className="right-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container left-container">
        <img src="tl/Madhava_Venkatesh.jpeg" alt="" />
        <div className="tl-text-box">
          <h2>
            Madhava Venkatesh {"("}Guest Lecture{")"}
          </h2>
          <small>6 PM</small>
          <a href="https://maps.app.goo.gl/5fRduL1ocaE75UKPA" target="_blank">
            Kalidas Auditorium
          </a>
          <span className="left-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container right-container">
        <img src="tl/optima.jpg" alt="" />
        <div className="tl-text-box">
          <h2>
            Result Declarartion and Closing Ceremony
          </h2>
          <small>6:30 PM</small>
          <a href="https://maps.app.goo.gl/5fRduL1ocaE75UKPA" target="_blank">
            Kalidas Auditorium
          </a>
          <span className="right-container-arrow"></span>
        </div>
      </div>
      {/* <div className="tl-container left-container">
        <img src="tl/AirIndia.jpg" alt="" />
        <div className="tl-text-box">
          <h2>
            Dr. Amit Kumar Das {"("}Guest Lecture{")"}
          </h2>
          <small>5:30 PM</small>
          <a href="https://maps.app.goo.gl/5fRduL1ocaE75UKPA" target="_blank">
            Kalidas Auditorium
          </a>
          <span className="left-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container right-container">
        <img src="tl/Blue_Yonder.jpg" alt="" />
        <div className="tl-text-box">
          <h2>NLP.py Finals</h2>
          <small>6:30 PM</small>
          <a href="https://maps.app.goo.gl/5fRduL1ocaE75UKPA" target="_blank">
            Kalidas Auditorium
          </a>
          <span className= "right-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container left-container">
        <img src="tl/ORMAE.jpeg" alt="" />
        <div className="tl-text-box">
          <h2>Operheimer Live Hackathon</h2>
          <small>6:30 PM</small>
          <a href="https://maps.app.goo.gl/WiBZ3Af6SkEHMK2p9" target="_blank">
            ORDS Lab, Department of Industrial and Systems Engineering
          </a>
          <span className="left-container-arrow"></span>
        </div>
      </div> */}
    </div>
  );
}
