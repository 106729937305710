import { useState } from "react";
import "../css/Sponsors.css";

export default function Sponsors() {
  document.title = "Sponsors | Optima 2023";

  const [show, setShow] = useState(2);

  const pastList = [
    {
      img: "spons/past/ACC.png",
      alt: "ACC",
      link: "https://acclimited.com/",
    },
    {
      img: "spons/past/AIBH.jpeg",
      alt: "AIBH",
      link: "https://aibh.in/",
    },
    {
      img: "spons/past/Allied_publishers.jpeg",
      alt: "Allied Publishers",
      link: "https://alliedpublishers.com/",
    },
    {
      img: "spons/past/GE_HealthCare.png",
      alt: "GE Healthcare",
      link: "https://gehealthcare.in/",
    },
    {
      img: "spons/past/RP_Industrial_Consultant.png",
      alt: "RP Industrial Consultant",
      link: "https://rpindustrials.com/",
    },
    {
      img: "spons/past/Springer.png",
      alt: "Springer",
      link: "https://springer.com/in",
    },
    {
      img: "spons/past/SR_Srinivasan.jpeg",
      alt: "SR Srinivasan",
      link: "http://www.srsrinivasan.com/",
    },
    {
      img: "spons/past/TATA.png",
      alt: "TATA",
      link: "https://www.tata.com/",
    },
    {
      img: "spons/past/Total_Library_Solution.png",
      alt: "Total Library Solution",
      link: "https://tlsgroup.co.in/",
    },
  ];

  const pastSponsors = (
    <div className="spons-box">
      <h1 className="spons-header">Past Associations</h1>
      <div className="spons-category">
        <div className="spons-group">
          {pastList.map((item, index) => (
            <a
              key={index}
              className="spons-img"
              href={item.link}
              target="_blank"
            >
              <img src={item.img} alt={item.alt} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );

  const eventSpons = [
    {
      img: "spons/2023/ORMAE.jpeg",
      alt: "Ormae",
      name: "Ormae",
      link: "https://ormae.com/",
      white: false,
    },
    {
      img: "spons/2023/DCVisionVR.jpg",
      alt: "DC Vision VR",
      name: "DC Vision VR",
      link: "https://dcvision.in/",
      white: false,
    },
    {
      img: "spons/2023/Blue_Yonder.png",
      alt: "Blue Yonder",
      name: "Blue Yonder",
      link: "https://blueyonder.com/",
      white: false,
    },
    {
      img: "spons/2023/Delhivery.png",
      alt: "Delhivery",
      name: "Delhivery",
      link: "https://delhivery.com/",
      white: false,
    },
    {
      img: "spons/2023/GFG.png",
      alt: "Geeks for Geeks",
      name: "Geeks for Geeks",
      link: "https://geeksforgeeks.org/",
      white: false,
    },
    {
      img: "spons/2023/Sabre.png",
      alt: "Sabre",
      name: "Sabre India",
      link: "https://sabre.com/",
      white: false,
    },
  ];

  const majorSpons = [
    {
      img: "spons/2023/AirIndia.png",
      alt: "Air India",
      name: "Air India",
      link: "https://www.airindia.com/",
      white: false,
    },
    {
      img: "spons/2023/FlexSim.jpg",
      alt: "FlexSim",
      name: "FlexSim",
      link: "https://flexsim.com/",
      white: false,
    },
  ];

  const titleSpons = [
    {
      img: "spons/2023/OPTYM.jpg",
      alt: "Optym",
      name: "Optym",
      link: "https://optym.com/",
      white: false,
    },
  ];
  const stratSpons = [
    {
      img: "spons/2023/RahulCommerce.jpg",
      alt: "Rahul Commerce",
      name: "Rahul Commerce",
      link: "http://rahulcom.com/",
      white: true,
    },
    {
      img: "spons/2023/Vicon.png",
      alt: "Vicon",
      name: "Vicon",
      link: "https://www.vicon.com/",
      white: true,
    },
  ];

  const merchPartner = [
    {
      img: "spons/2023/OwlPrints.png",
      alt: "Owl Prints",
      name: "Owl Prints",
      link: "https://owlprints.in/",
      white: false,
    },
  ];

  const mediaPartner = [
    {
      img: "spons/2023/CampusVarta.png",
      alt: "Campus Varta",
      name: "Campus Varta",
      link: "https://www.campusvarta.com/",
      white: false,
    },
    {
      img: "spons/2023/WomansEra.png",
      alt: "Woman's Era",
      name: "Woman's Era",
      link: "https://womansera.com/",
      white: false,
    },
  ];

  const sponsors2023 = (
    <div className="spons-box">
      <h1 className="spons-header">Sponsors - Optima 2023</h1>
      <div className="spons-category">
        <h2>Title Sponsor</h2>
        <div className="spons-group">
          {titleSpons.map((item, index) => (
            <a
              key={index}
              className="spons-img-major"
              href={item.link}
              target="_blank"
            >
              <img
                src={item.img}
                alt={item.alt}
                style={{
                  backgroundColor: item.white ? "white" : "",
                }}
              />
              <h4
                style={{
                  color: "white",
                }}
              >
                {item.name}
              </h4>
            </a>
          ))}
        </div>
      </div>
      <div className="spons-category">
        <h2>Major Sponsors</h2>
        <div className="spons-group">
          {majorSpons.map((item, index) => (
            <a
              key={index}
              className="spons-img-major"
              href={item.link}
              target="_blank"
            >
              <img
                src={item.img}
                alt={item.alt}
                style={{
                  backgroundColor: item.white ? "white" : "",
                }}
              />
              <h4
                style={{
                  color: "white",
                }}
              >
                {item.name}
              </h4>
            </a>
          ))}
        </div>
      </div>
      <div className="spons-category">
        <h2>Strategic Partners</h2>
        <div className="spons-group">
          {stratSpons.map((item, index) => (
            <a
              key={index}
              className="spons-img"
              href={item.link}
              target="_blank"
            >
              <img
                src={item.img}
                alt={item.alt}
                style={{
                  backgroundColor: item.white ? "white" : "",
                }}
              />
              <h4
                style={{
                  color: "white",
                }}
              >
                {item.name}
              </h4>
            </a>
          ))}
        </div>
      </div>
      <div className="spons-category">
        <h2>Events Partners</h2>
        <div className="spons-group">
          {eventSpons.map((item, index) => (
            <a
              key={index}
              className="spons-img"
              href={item.link}
              target="_blank"
            >
              <img
                src={item.img}
                alt={item.alt}
                style={{
                  backgroundColor: item.white ? "white" : "",
                }}
              />
              <h4
                style={{
                  color: "white",
                }}
              >
                {item.name}
              </h4>
            </a>
          ))}
        </div>
        <div className="spons-category">
          <h2>Merchandise Partner</h2>
          <div className="spons-group">
            {merchPartner.map((item, index) => (
              <a
                key={index}
                className="spons-img"
                href={item.link}
                target="_blank"
              >
                <img
                  src={item.img}
                  alt={item.alt}
                  style={{
                    backgroundColor: item.white ? "white" : "",
                  }}
                />
                <h4
                  style={{
                    color: "white",
                  }}
                >
                  {item.name}
                </h4>
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="spons-category">
        <h2>Media Partners</h2>
        <div className="spons-group">
          {mediaPartner.map((item, index) => (
            <a
              key={index}
              className="spons-img"
              href={item.link}
              target="_blank"
            >
              <img
                src={item.img}
                alt={item.alt}
                style={{
                  backgroundColor: item.white ? "white" : "",
                }}
              />
              <h4
                style={{
                  color: "white",
                }}
              >
                {item.name}
              </h4>
            </a>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <section className="spons-page">
      <div className="spons-chooser">
        <button
          onClick={() => {
            setShow(1);
          }}
          className={"spons-btn" + (show == 1 ? " active" : "")}
        >
          Past Sponsors
        </button>
        <button
          onClick={() => {
            setShow(2);
          }}
          className={"spons-btn" + (show == 2 ? " active" : "")}
        >
          2023 Sponsors
        </button>
      </div>
      {show == 1 ? pastSponsors : sponsors2023}
    </section>
  );
}
