import "../css/TeamCard.css";

export default function TeamCard({
  img,
  name,
  portfolio,
  email,
  fb,
  linkedin,
}) {
  return (
    <div className="TeamCard-card">
      <div className="TeamCard-img-container">
        <img src={img} alt={name} />
      </div>
      <h3>{name}</h3>
      <p>{portfolio}</p>
      <div className="TeamCard-icons">
        <a href={"mailto:" + email}>
          <i className="fa-regular fa-envelope"></i>
        </a>
        <a href={fb} target="_blank">
          <i className="fab fa-facebook"></i>
        </a>
        <a href={linkedin} target="_blank">
          <i className="fab fa-linkedin-in"></i>
        </a>
      </div>
    </div>
  );
}
