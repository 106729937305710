export default function Day1() {
  //   return <h1 style={{ marginBlock: "2rem" }}>Coming Soon</h1>;
  return (
    <div className="tl tl-day1">
      <div className="tl-container left-container">
        <img src="tl/DataAnalysis.jpg" alt="" />
        <div className="tl-text-box">
          <h2>TCS Data Analytics Workshop</h2>
          <small>9 AM</small>
          <a href="https://maps.app.goo.gl/4E9RiAob3fenRGjj9" target="_blank">
            NR-312, Nalanda Classroom Complex
          </a>
          <span className="left-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container right-container">
        <img src="tl/FlexSim.jpg" alt="" />
        <div className="tl-text-box">
          <h2>
            FlexSim Workshop with Mano Kanthanathan {"("}Guest Lecture{")"}{" "}
          </h2>
          <small>9 AM</small>
          <a href="https://maps.app.goo.gl/4E9RiAob3fenRGjj9" target="_blank">
            NR-311, Nalanda Classroom Complex
          </a>
          <span className="right-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container left-container">
        <img src="tl/FlexSim.jpg" alt="" />
        <div className="tl-text-box">
          <h2>
            FlexSim Workshop {"("}continued{")"}{" "}
          </h2>
          <small>1:30 PM</small>
          <a href="https://maps.app.goo.gl/4E9RiAob3fenRGjj9" target="_blank">
            NR-311, Nalanda Classroom Complex
          </a>
          <span className="left-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container right-container">
        <img src="tl/Fico.png" alt="" />
        <div className="tl-text-box">
          <h2>FICO Workshop</h2>
          <small>3 PM</small>
          <a href="https://maps.app.goo.gl/4E9RiAob3fenRGjj9" target="_blank">
            NR-312, Nalanda Classroom Complex
          </a>
          <span className="right-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container left-container">
        <img src="tl/Sabre.jpg" alt="" />
        <div className="tl-text-box">
          <h2>Fin-A-lytics</h2>
          <small>11 AM</small>
          <a href="https://maps.app.goo.gl/4E9RiAob3fenRGjj9" target="_blank">
            NR-312, Nalanda Classroom Complex
          </a>
          <span className="left-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container right-container">
        <img src="tl/Delhivery.jpg" alt="" />
        <div className="tl-text-box">
          <h2>NetworSify</h2>
          <small>9 AM</small>
          <a href="https://maps.app.goo.gl/WiBZ3Af6SkEHMK2p9" target="_blank">
            C1, Department of Industrial and Systems Engineering
          </a>
          <span className="right-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container left-container">
        <img src="tl/InnovateX.png" alt="" />
        <div className="tl-text-box">
          <h2>Innovate-X</h2>
          <small>9 AM</small>
          <a href="https://maps.app.goo.gl/WiBZ3Af6SkEHMK2p9" target="_blank">
            C2, Department of Industrial and Systems Engineering
          </a>
          <span className="left-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container right-container">
        <img src="tl/Blue_Yonder.jpg" alt="" />
        <div className="tl-text-box">
          <h2>NLP.py</h2>
          <small>9 AM</small>
          <a href="https://maps.app.goo.gl/WiBZ3Af6SkEHMK2p9" target="_blank">
            E1, Department of Industrial and Systems Engineering
          </a>
          <span className="right-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container left-container">
        <img src="tl/DCVisionVR.png" alt="" />
        <div className="tl-text-box">
          <h2>360 Degrees</h2>
          <small>2:30 PM</small>
          <a href="https://maps.app.goo.gl/WiBZ3Af6SkEHMK2p9" target="_blank">
            E1, Department of Industrial and Systems Engineering
          </a>
          <span className="left-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container right-container">
        <img src="tl/Deeptak_Chatterjee.jpg" alt="" />
        <div className="tl-text-box">
          <h2>
            Deeptak Chatterjee {"("}Guest Lecture{")"}
          </h2>
          <small>4:30 PM</small>
          <a href="https://maps.app.goo.gl/5fRduL1ocaE75UKPA" target="_blank">
            Kalidas Auditorium
          </a>
          <span className="right-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container left-container">
        <img src="tl/Amit_Kumar_Das.jpeg" alt="" />
        <div className="tl-text-box">
          <h2>
            Dr. Amit Kumar Das {"("}Guest Lecture{")"}
          </h2>
          <small>5:30 PM</small>
          <a href="https://maps.app.goo.gl/5fRduL1ocaE75UKPA" target="_blank">
            Kalidas Auditorium
          </a>
          <span className="left-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container right-container">
        <img src="tl/Blue_Yonder.jpg" alt="" />
        <div className="tl-text-box">
          <h2>NLP.py Finals</h2>
          <small>6:30 PM</small>
          <a href="https://maps.app.goo.gl/5fRduL1ocaE75UKPA" target="_blank">
            Kalidas Auditorium
          </a>
          <span className="right-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container left-container">
        <img src="tl/ORMAE.jpeg" alt="" />
        <div className="tl-text-box">
          <h2>Operheimer Live Hackathon</h2>
          <small>6:30 PM</small>
          <a href="https://maps.app.goo.gl/WiBZ3Af6SkEHMK2p9" target="_blank">
            ORDS Lab, Department of Industrial and Systems Engineering
          </a>
          <span className="left-container-arrow"></span>
        </div>
      </div>
    </div>
  );
}
