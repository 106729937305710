import { useState } from "react";
import "../css/Schedule.css";
import Day0 from "../components/Day0";
import Day1 from "../components/Day1";
import Day2 from "../components/Day2";

export default function Schedule() {
  document.title = "Schedule | Optima 2023";

  const [day, setDay] = useState(2);

  return (
    <section className="TL-page">
      <h1 style={{ marginBottom: "1em" }}>Schedule</h1>
      <div className="spons-chooser">
        <button
          onClick={() => {
            setDay(0);
          }}
          className={"spons-btn" + (day == 0 ? " active" : "")}
        >
          Day 0
        </button>
        <button
          onClick={() => {
            setDay(1);
          }}
          className={"spons-btn" + (day == 1 ? " active" : "")}
        >
          Day 1
        </button>
        <button
          onClick={() => {
            setDay(2);
          }}
          className={"spons-btn" + (day == 2 ? " active" : "")}
        >
          Day 2
        </button>
      </div>
      {day == 0 ? <Day0 /> : day == 1 ? <Day1 /> : <Day2 />}
    </section>
  );
}
