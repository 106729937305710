export default function Day0() {
  return (
    <div className="tl tl-day0">
      <div className="tl-container left-container">
        <img src="tl/optima.jpg" alt="" />
        <div className="tl-text-box">
          <h2>Opening Ceremony</h2>
          <small>5:30 PM</small>
          <a href="https://maps.app.goo.gl/5fRduL1ocaE75UKPA" target="_blank">
            Kalidas Auditorium
          </a>
          <span className="left-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container right-container">
        <img src="tl/OPTYM.jpg" alt="" />
        <div className="tl-text-box">
          <h2>Presentation by Optym</h2>
          <small>7:15 PM</small>
          <a href="https://maps.app.goo.gl/5fRduL1ocaE75UKPA" target="_blank">
            Kalidas Auditorium
          </a>
          <span className="right-container-arrow"></span>
        </div>
      </div>
      <div className="tl-container left-container">
        <img src="tl/MK_Tiwari.jpeg" alt="" />
        <div className="tl-text-box">
          <h2>Talk by Prof. M.K.Tiwari</h2>
          <small>8 PM</small>
          <a href="https://maps.app.goo.gl/5fRduL1ocaE75UKPA" target="_blank">
            Kalidas Auditorium
          </a>
          <span className="left-container-arrow"></span>
        </div>
      </div>
    </div>
  );
}
