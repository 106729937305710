import "../css/About.css";

export default function About() {
  document.title = "About | Optima 2023";

  return (
    <div className="about-conatiner">
      <div className="about_title">
        <h1>About OPTIMA</h1>
      </div>
      <div className="text_area-about">
        <div className="about-top-section">
          <img data-aos="fade-right" src="sample.jpg" alt="Optima 2023" />
          <img data-aos="fade-left" src="deptLogo.png" alt="ISE Dept" />
        </div>
        <br />
        <p data-aos="fade-up" className="p-about">
          <b>Optima</b> is The Techno-Optimization Fest of IIT Kharagpur! A
          nexus of innovation, problem-solving, and interdisciplinary
          collaboration, Optima is orchestrated by the esteemed{" "}
          <b>Department of Industrial and Systems Engineering</b>. This dynamic
          platform unites aspiring engineers, researchers, and industry experts
          to explore and revolutionize industrial systems optimization. With a
          focal point on technology-optimization synergy, Optima addresses
          real-world challenges across sectors like supply chain management,
          production line optimization, and more.
        </p>
        <br />
        <p data-aos="fade-down" className="p-about">
          It is <b>back again!</b> Celebrating{" "}
          <b>
            50 years of Department of Industrial and Systems Engineering at IIT
            Kharagpur
          </b>
          , we bring to you the biggest and most awaited event of the year. Mark
          your calendars -{" "}
          <b
            style={{
              color: "orange",
            }}
          >
            <u>6th - 8th October 2023</u>
          </b>
        </p>
        <br />
        <p data-aos="fade-right" className="p-about">
          The event offers a rich mix of{" "}
          <b>competitions, workshops, seminars, and interactive sessions</b>,
          driving sustainable advancements, efficiencies, and productivity. From
          hackathons fueling creativity to networking fostering
          interdisciplinary insights, Optima's industrial connections shine. It
          stands as a beacon of innovation, fueling progress within evolving
          industrial systems. Join us to dive into the realms of optimization,
          where excellence meets practical solutions!
        </p>
        <br />
        <p data-aos="fade-left" className="p-about">
          #Optima2023 #ISE #IITKgp #IIT #optima #kharagpur #ise #iitian #iitfest
          #IndustrialEngineering #Innovation #Competition #Event #collegefest
          #engineering #technology #innovation #students #engineers
          #techenthusiasts
        </p>
      </div>
      <div data-aos="fade-up" className="social">
        <ul className="social-icons-about">
          <li>
            <a href="https://www.facebook.com/optima.iitkgp" target="_blank">
              <i className="fab fa-facebook "></i>
            </a>
          </li>
          <li>
            <a href="https://instagram.com/optima.iitkgp" target="_blank">
              <i className="fab fa-instagram"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/optima-iitkgp/"
              target="_blank"
            >
              <i className="fab fa-linkedin"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
