import { Link } from "react-router-dom";
import "../css/Register.css";

const RegisterForm = () => {
  document.title = "Register | Optima 2023";

  return (
    <section className="register-page">
      <div className="register-box">
        <img src="logo_transparent.png" alt="Logo" />
        <h1>Benefits for Participants</h1>
        <ol>
          <li>
            4 days of accommodation from{" "}
            <b style={{ color: "orange" }}>5th to 8th October</b> within the IIT
            Kharagpur Campus.
          </li>
          <li>
            Gain exclusive access to a diverse range of events, including
            competitions, guest lectures, and workshops at Optima 2023 within
            IIT Kharagpur spanning 2200 acres.
          </li>
          <li>
            Receive a comprehensive participation kit, comprising a carry bag,
            an official Optima 2023 T-shirt, a notepad, an ID card, and
            essential stationery.
          </li>
        </ol>
      </div>
      <div className="register-box">
        <h1>Rules and Regulations</h1>
        <ol>
          <li>
            Attend the Fest on-campus by paying a one-time fee {" ("}
            non-refundable{")"} of <b style={{ color: "orange" }}>₹1000</b>
            {" ("}exclusively applicable to non-IIT Kharagpur students{")"}. If
            you pay <b style={{ color: "orange" }}>₹600</b>, accommodation will
            not be provided.
          </li>
          <li>
            While the fee does not cover food and travel expenses, participants
            have the convenience of accessing meals from nearby restaurants,
            night-time canteens, campus food courts, and food stalls located
            within the event venue.
          </li>
          <li>
            If you are participating in any competitions or workshop, you are
            required to{" "}
            <b style={{ color: "orange" }}> bring your own laptop.</b>
          </li>
          <li>
            Upon reaching the campus, you need to report to the <br />
            <a
              href="https://goo.gl/maps/QbvkhBVdd7jr1Gbr6"
              target="_blank"
              style={{ color: "orange" }}
            >
              <i className="fa fa-map-marker-alt" /> Department of Industrial
              and Systems Engineering
            </a>
          </li>
          <li>
            Experience comfortable shared accommodation within campus hostels,
            thoughtfully segregated for both female and male participants.
          </li>
          <li>
            All college students are cordially invited to attend, provided they
            carry their current college ID with a photo for smooth entry to the
            IIT campus.
          </li>
          <li>
            Uphold the high standards of decorum and cleanliness set by the
            Institute and adhere diligently to its rules.
          </li>
          <li>
            In the unlikely event of any damage to Institute property,
            participants are kindly requested to assume responsibility for
            restitution. In such a case, participants will be subjected to a
            fine as determined by the Institute adminstration and Optima
          </li>
          <li>
            For the safety and well-being of all, please be advised that the
            introduction of narcotics, cigarettes, alcohol, drugs, explosives,
            or any hazardous items onto the campus is strictly prohibited.
          </li>
          <li>
            In the event of disputes, rest assured that the final resolution
            will be reached impartially and with integrity by the Security and
            Team Optima.
          </li>
          <li>
            While Optima 2023 and IIT Kharagpur prioritize your well-being, it
            is important to note that neither entity can assume responsibility
            for any incidents during your stay. Furthermore, participants are
            encouraged to safeguard their personal belongings, as Optima cannot
            be held liable for any losses or damages incurred.
          </li>
          <li>
            In case of any infringement of the established rules and
            regulations, participants may face disqualification, and, in rare
            instances, may be courteously requested to vacate the premises.
          </li>
        </ol>
        {/* <a href="https://forms.gle/CcXt1mxdXThK2DCS8" target="_blank">
        </a> */}
        {/* <Link to="/registration" style={{ margin: "auto" }}>
          <button className="btn">Register Now</button>
        </Link> */}
        <br />
        <center>Registration has closed. Kindly apply next year.</center>
      </div>
    </section>
  );
};

export default RegisterForm;
