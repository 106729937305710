import WSData from "../data/ws.json";
import "../css/GuestLectures.css";

function WSBox(data, idx) {
  const { id, img, name, topic, desc, date, time, venue, link } = data;
  return (
    <div
      key={idx}
      className={"GL-box" + (id % 2 == 0 ? " inverted" : "")}
      data-aos={"fade-" + (id % 2 == 0 ? "left" : "right")}
      data-aos-duration="1500"
    >
      <div className="GL-box-img">
        <img src={img} alt="" />
        {/* <h3>{name}</h3> */}
      </div>
      <div className="GL-box-content">
        <h3>{topic}</h3>
        <p></p>
        <p>{desc}</p>
        {/* <div className="GL-schedule">
          <div className="GL-datetime">
            <p><i class="far fa-calendar-alt"></i> {date}</p>
            <p><i class="fa-regular fa-clock"></i> {time}</p>
          </div>
          <div className="GL-loc">
            <p><i className="fas fa-map-marker-alt"></i> {venue}</p>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default function Workshops() {
  document.title = "Guest Lectures | Optima 2023";

  return (
    <div className="gl-page">
      <section className="gl-section">
        <h1>Workshops</h1>
        <br />
        {WSData.ws.map(WSBox)}
      </section>
    </div>
  );
}
