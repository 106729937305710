import React from "react";
import { Link } from "react-router-dom";
import "../css/Home.css";
import optima from "../images/home/optima.jpg";
import DeptLogo from "../images/home/DeptLogo.png";

export default function Home() {
  document.title = "Optima 2023 | IIT Kharagpur";

  return (
    <section className="Home-page">
      <section className="Home-heading">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
          data-bs-interval="3000"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="5"
              aria-label="Slide 6"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="6"
              aria-label="Slide 7"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="7"
              aria-label="Slide 8"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="carousel/optima-carousel-1.png"
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item">
              <img
                src="carousel/banner.png"
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item">
              <img
                src="carousel/optima-carousel-3.jpg"
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item">
              <img
                src="carousel/optima-carousel-4.jpg"
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item">
              <img
                src="carousel/optima-carousel-5.jpg"
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item">
              <img
                src="carousel/optima-carousel-6.jpg"
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item">
              <img
                src="carousel/optima-carousel-7.jpg"
                className="d-block w-100"
                alt="..."
              />
            </div>
            <div className="carousel-item">
              <img
                src="carousel/optima-carousel-2.jpg"
                className="d-block w-100"
                alt="..."
              />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>

      <div className="Home-content" data-aos="fade">
        <h1>
          IIT Kharagpur's <span>Techno-Optimization Fest</span> is back
        </h1>
        <Link to="/register" className="btn">
          Register Now
        </Link>
      </div>

      <section className="Home-about">
        <h1 className="Home-about-heading">
          <span>about </span> us
        </h1>
        <div className="Home-about-row">
          <div className="Home-about-image" data-aos="fade-right">
            <img src={optima} alt="" />
          </div>
          <div className="Home-about-content" data-aos="fade-down">
            <h3>Optima - IIT Kharagpur's Techno-Optimization Fest</h3>
            <p>
              A nexus of innovation, problem-solving, and interdisciplinary
              collaboration, Optima is orchestrated by the esteemed Department
              of Industrial and Systems Engineering. With a focal point on
              technology-optimization synergy, Optima addresses real-world
              challenges across sectors like supply chain management, production
              line optimization, and more.
            </p>
            <p>
              The event, from{" "}
              <b
                style={{
                  color: "orange",
                }}
              >
                6th - 8th October 2023
              </b>
              , offers a rich mix of competitions, workshops, seminars, and
              interactive sessions, driving sustainable advancements,
              efficiencies, and productivity.
            </p>
            <Link to="/register" className="btn">
              Register Now
            </Link>
          </div>
        </div>
        <div className="Home-aboutDept-row">
          <div className="Home-aboutDept-content" data-aos="fade-up">
            <h3>
              Department of Industrial and Systems Engineering - IIT Kharagpur
            </h3>
            <p>
              With its inception in 1973, the Department of Industrial and
              Systems Engineering at IIT Kharagpur embarked on a remarkable
              journey that has left an indelible mark on the landscape of
              engineering education and research.
            </p>
            <p>
              The Department caters to a broad spectrum of domains, showcasing
              its versatility and applicability in fields like:{" "}
              <b>Data Analytics</b>, Quality Engineering and Process
              Transformations, Information Systems and E-Business,{" "}
              <b>Logistics and Supply Chain Management</b>, System Thinking and
              Policy Planning, Human Factors Engineering and Ergonomics,{" "}
              <b>Operations Research and Optimization</b>, Safety Analytics and
              Virtual Reality
            </p>
            <a href="http://www.iem.iitkgp.ac.in/" className="btn">
              Visit Us
            </a>
          </div>
          <div className="Home-aboutDept-image" data-aos="fade-left">
            <img src={DeptLogo} alt="" />
          </div>
        </div>
      </section>

      {/* <section className="Home-gallery">
        <h1 className="Home-gallery-heading">
          our <span>gallery</span>
        </h1>
        <div className="Home-gallery-box-container">
          <div className="Home-gallery-box" data-aos="fade-right">
            <img src={optima} alt="" />
          </div>
          <div className="Home-gallery-box" data-aos="fade-down">
            <img src={optima} alt="" />
          </div>
          <div className="Home-gallery-box" data-aos="fade-up">
            <img src={optima} alt="" />
          </div>
          <div className="Home-gallery-box" data-aos="fade-left">
            <img src={optima} alt="" />
          </div>
          <div className="Home-gallery-box" data-aos="fade-down">
            <img src={optima} alt="" />
          </div>
          <div className="Home-gallery-box" data-aos="fade-right">
            <img src={optima} alt="" />
          </div>
          <div className="Home-gallery-box" data-aos="fade-left">
            <img src={optima} alt="" />
          </div>
          <div className="Home-gallery-box" data-aos="fade-up">
            <img src={optima} alt="" />
          </div>
        </div>
      </section> */}

      <section className="Hmsg">
        <div className="Hmsg-row" data-aos="fade-up" data-aos-duration="2000">
          <h1 className="Hmsg-heading">
            HOD's<span> message </span>
          </h1>
          <div className="Hmsg-image">
            <img src="pfp_team/hod.jpg" alt="" />
          </div>
          <div className="Hmsg-content">
            <h4
              style={{
                textAlign: "center",
              }}
            >
              Prof. Jhareswar Maiti
            </h4>
            <p>
              As the Department of Industrial and Systems Engineering marks its
              50th anniversary, I am delighted to present to you Optima, the
              techno-optimization fest of IIT Kharagpur, meticulously organised
              by our department. Optima offers a distinctive platform for
              students to delve into this domain and experience world-class
              research, while providing brands with an opportunity to engage
              with one of the most astute and diverse audiences accross various
              domains. i extend a heartfelt invitation to all of you to become
              integral participants in the journey of IIT Kharagpur and the
              Department of Industrial and Systems Engineering.
            </p>
            <p></p>
          </div>
          <div className="PIC-box">
            <div className="PIC-block" data-aos="fade-right">
              <div className="PIC-img-container">
                <img src="pfp_team/gsen.jpg" alt="" />
              </div>
              <h3>
                Prof. <br />
                Goutam Sen
              </h3>
              <p>chairman</p>
              <div className="PIC-icons">
                <a href={"mailto:gsen@iem.iitkgp.ac.in"}>
                  <i className="fa-regular fa-envelope"></i>
                </a>

                <a
                  href="https://www.linkedin.com/in/goutam-a-sen-71512712b/"
                  target="_blank"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div className="PIC-block" data-aos="fade-left">
              <div className="PIC-img-container">
                <img src="pfp_team/mamata.jpg" alt="" />
              </div>
              <h3>
                Prof. <br /> Mamata Jenamani
              </h3>
              <p>Co-Chairman</p>
              <div className="PIC-icons">
                <a href={"mailto:mj@iem.iitkgp.ac.in"}>
                  <i className="fa-regular fa-envelope"></i>
                </a>
                <a
                  href="https://www.linkedin.com/in/mamata-jenamani-6a93b894/"
                  target="_blank"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
